import Row from "@Containers/components/Row";
import Column from "@Containers/components/Column";
import {useTranslation} from "@Translation/hooks/useTranslation";
import IconArrowSwitch from "@Icons/IconArrowSwitch";
import useIsMobile from "@Hooks/selector/useIsMobile";
import truncateName from "@String/truncateName";
import parseHTMLStrings from "@String/parseHTMLStrings";
import {useLocationToLocationStationsSwitchEvent} from "@Events/hooks/useLocationToLocationStationsSwitchEvent";

function RouteDescription() {
    const imageHeadingLabel = useTranslation("sts_page.image_heading")
    // const {reverseDirectionUrl} = window
    const isMobile = useIsMobile()

    const getStationName = (name: string) => (
        parseHTMLStrings(isMobile ? truncateName(name, 20) : name)
    )

    const dispatchLocationToLocationStationsSwitchEvent = useLocationToLocationStationsSwitchEvent("")
    const handleSwitch = async () => {
        // await dispatchLocationToLocationStationsSwitchEvent()
        // window.location.href = reverseDirectionUrl
    }

    return (
        <Column className="gb--search-2-form__pre-content">
            {imageHeadingLabel.toUpperCase()}
            <Row gap center className="gb--search-2-form__pre-content_title" wrap>
                <span>
                    {getStationName(window.departingStationName)}
                </span>
                <button
                    type="button"
                    onClick={handleSwitch}
                    style={{cursor: "default"}}
                    className="gb--search-2-form__pre-content_icon"
                >
                    <IconArrowSwitch/>
                </button>
                <span>
                     {getStationName(window.destinationStationName)}
                </span>
            </Row>
        </Column>
    )
}

export default RouteDescription