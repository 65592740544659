import React from 'react';
import GenericSvgIcon from "../GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconCustomerSupport(props: IconType) {
    const {
        width = '40',
        height = '40',
        viewBox = '0 0 40 40',
        title = 'IconCustomerSupport',
        ...rest
    } = props
    return (
        <GenericSvgIcon
            {...{width, height, viewBox, title, ...rest}}
            svgIconPath={(
                <g fill="none" transform="translate(3)">
                    <path
                        d="m28.9759048 38.1377448-7.7459473-3.1404911c-.5233748 1.8494003-2.6866574 2.8962307-4.2567818 3.1055968v.0348943c-.2442416 0-.6629415-.0348943-.6978331-.0348943-1.5003412-.2791548-2.8960073-1.2910908-3.3844905-3.0707024l-7.74594729 3.1404911c7.71105559 2.4426042 16.08505269 2.4426042 23.83099999-.0348944z"
                        fill="#fff"
                    />
                    <path
                        d="m21.2297699 32.9734843v2.267871c-1.2211924 4.1868387-7.1526987 4.1868387-8.339 0v-2.267871"
                        fill="#f7ebde"
                    />
                    <path
                        d="m28.3130206 18.2487962c0-9.21162612-4.5708301-13.92211678-11.270062-13.92211678s-11.27006205 4.74538318-11.27006205 13.92211678c-2.16329365.1744627-1.67480798 5.0943085.76762032 4.8151683.69783666 1.9888738 1.46545699 3.6288224 2.26796915 5.0245233 2.75645478 4.7104907 5.89671978 6.1061916 8.19958078 6.1061916 2.9658058 0 7.3621767-2.3029065 10.4675499-11.0958224 2.5122119.2442477 3.0006976-4.6755981.8374039-4.8500608z"
                        fill="#f7ebde"
                    />
                    <g fill="#070c63">
                        <path
                            d="m5.17958109 39.4287395c-.41871557 0-.80253817-.244254-.97700299-.6629752-.20935779-.5234015.03489296-1.1514833.55828742-1.3608439l7.71134508-3.1404089c.5233944-.2093606 1.1514678.0348934 1.3608256.5582949s-.034893 1.1514833-.5582875 1.3608439l-7.71134501 3.1404089c-.13957185.0697869-.27914371.1046803-.3838226.1046803z"
                        />
                        <path
                            d="m28.9411495 39.42899c-.1395678 0-.2791356-.0348951-.3838114-.0697902l-7.7111214-3.14056c-.5233793-.2093707-.8025149-.8374827-.5582713-1.3609093.2093518-.5234267.8374069-.8025876 1.3607862-.5583218l7.7111214 3.14056c.5233792.2093706.8025149.8374826.5582712 1.3609093-.1744598.3838462-.5931632.628112-.9769747.628112z"
                        />
                        <path
                            d="m17.0428975 39.4284261c-2.4773272 0-4.5010593-1.5352193-5.164006-3.9078308-.1744597-.5582616.1744596-1.1514145.7327306-1.2909798.5582709-.1744568 1.1514337.1744567 1.2910015.7327182.5233789 1.7794587 1.9539482 2.4075029 3.1751658 2.4075029s2.6517869-.6280442 3.1751659-2.4075029c.1744596-.5582615.7327305-.8722836 1.2910015-.7327182.5582709.1744567.8722983.7327182.7327305 1.2909798-.7327305 2.3726115-2.7564626 3.9078308-5.2337898 3.9078308z"
                        />
                        <path
                            d="m17.043174 35.2064913c-1.8842313 0-5.5829075-.8025221-8.89775883-6.2806076-.31403855-.4884916-.13957269-1.1514447.34893171-1.4305828.48850441-.3140304 1.15147467-.139569 1.43062005.3489227 2.79145377 4.6057788 5.65269387 5.2687318 7.11820707 5.2687318 1.7446586 0 6.1411982-1.0118756 9.4909427-10.3978947.209359-.5582762.8025429-.8374143 1.3259405-.6280607.5582908.2093536.8374361.8025221.6280771 1.325906-3.7684625 10.6421406-9.2466905 11.7935853-11.4449603 11.7935853z"
                        />
                    </g>
                    <path
                        d="m28.4527322 18.2837456c-.0352413 0-.0697847 0-.1395693 0 0-9.21166373-4.5708923-13.9221736-11.2702154-13.9221736-4.884923 0-8.65329227 2.51227193-10.32812304 7.4670305-.59316923 1.7097406-.9072 3.6986225-.9420923 5.9666458 2.69089477-6.4090848 6.22827694-3.9899763 10.94711264-1.5712167 3.2962763 1.6898518 7.1693224 3.3800525 11.7328874 2.059714"
                        fill="#f5a623"
                        opacity=".25"
                    />
                    <path
                        d="m25.103059 19.819451c-3.8029018 0-7.0831182-1.7098476-9.8396027-3.1405365-2.3726702-1.2562146-4.4313104-2.3030601-5.89678319-1.7796374-.97698183.3489485-1.84928703 1.4306889-2.61691562 3.280116-.20935325.5234227-.83741299.7676867-1.36079612.5583176-.52338312-.2093691-.76762858-.8374764-.55827533-1.3608992 1.01187404-2.4426395 2.26799354-3.8733284 3.83814291-4.4665409 2.33777795-.8374764 4.78023255.4187382 7.57160915 1.884322 3.4194364 1.7796373 7.2924716 3.8035387 11.898243 2.4775344.5586243-.1744743 1.1514429.1395794 1.2910117.697897.1744611.5583176-.1395688 1.15153-.6978441 1.2911094-1.2561195.3838434-2.4773468.5234228-3.6287897.5583176"
                        fill="#070c63"
                    />
                    <path
                        d="m29.220414 25.7503943-2.8263077-.4884824 1.9190978-11.1304186 2.8263077.4884823c1.221244.2093496 2.0237758 1.3956638 1.8144197 2.6168696l-1.1514587 6.6642945c-.2093561 1.2212059-1.3608148 2.0586041-2.5820588 1.8492546z"
                        fill="#f5a623"
                    />
                    <path
                        d="m29.6042137 26.832339c-.1744622 0-.3838169 0-.5582791-.0348924l-2.6518257-.4536018c-.5582791-.1046773-.942096-.628064-.8723111-1.1863431l1.7446222-11.1655821c.0348924-.2791395.2093546-.5233866.4187093-.6978489.2093547-.1744622.5233867-.2093546.8025262-.1744622l2.826288.4884942c1.7795146.314032 3.0007502 2.0237618 2.6518257 3.8381689l-1.1863431 6.6644568c-.2093546 1.57016-1.5701599 2.7216106-3.1752124 2.7216106zm-1.8144071-2.4075786 1.6050525.2791396c.0697849 0 .1395697.0348924.2093546.0348924.5931716 0 1.0816658-.4187093 1.1863431-1.0118809l1.1863431-6.6644568c.1046774-.6629564-.314032-1.2910204-.9769884-1.3956978l-1.7795147-.314032z"
                        fill="#070c63"
                    />
                    <path
                        d="m4.86556853 25.7503943 2.82630767-.4884824-1.9190978-11.1304186-2.82630767.4884823c-1.22124405.2093496-2.02377586 1.3956638-1.81441974 2.6168696l1.15145868 6.6642945c.20935613 1.2212059 1.36081481 2.0586041 2.58205886 1.8492546z"
                        fill="#f5a623"
                    />
                    <path
                        d="m4.48172861 26.832339c-1.60505243 0-2.96585775-1.1514506-3.2449973-2.7216106l-1.1863431-6.6644568c-.314032-1.7795147.8723111-3.4892444 2.65182575-3.8381689l2.82628797-.4884942c.27913955-.0348924.5582791 0 .76763377.1744622.24424711.1744623.38381688.4187094.41870933.6629565l1.95397686 11.0957972c.10467734.5582791-.27913955 1.1165582-.83741865 1.2212355l-2.82628797.4884943c-.13956978.0348924-.314032.0697848-.52338666.0697848zm.41870932-11.4796141-1.81440709.314032c-.66295643.1046774-1.08166576.7327414-.97698843 1.3956978l1.1863431 6.6644568c.10467733.5931716.59317155 1.0118809 1.1863431 1.0118809.06978488 0 .13956977 0 .20935466-.0348924l1.81440709-.314032z"
                        fill="#070c63"
                    />
                    <path
                        d="m3.57471099 15.108h-.03489183c-.59316115-.0348915-1.01186315-.5233718-1.01186315-1.0816351.3140265-7.88546883 6.66434004-14.0263649 14.51500239-14.0263649 7.711095 0 14.0614086 6.03622171 14.4801105 13.7472333.0348919.5931547-.4187019 1.0816351-.9769713 1.1165265-.5931611.0348915-1.0816468-.4186974-1.1165386-.9769607-.3489183-6.59448501-5.7920442-11.75842035-12.3866006-11.75842035-6.6992319 0-12.14235777 5.2686097-12.42149243 12.00266055 0 .5233718-.48848565.9769607-1.04675498.9769607z"
                        fill="#070c63"
                    />
                    <path
                        d="m28.3131364 19.2959021c-.5931686 0-1.046768-.4536122-1.046768-1.0467973 0-8.1650197-3.7334725-12.87560789-10.2234341-12.87560789-4.4662101 0-7.64140633 2.19827451-9.17666605 6.35057079-.2093536.558292-.80252213.8025447-1.36079839.6280785-.55827626-.2093595-.80252213-.8025447-.6280608-1.3608366 1.88418239-4.95484101 5.82700854-7.71140746 11.16552524-7.71140746 7.7111909 0 12.3169701 5.58291939 12.3169701 14.96920266 0 .5931851-.4884918 1.0467973-1.046768 1.0467973z"
                        fill="#070c63"
                    />
                    <path
                        d="m29.1565316 26.0002069c-.5995371 0-1.0580066.4628688-1.0580066 1.0681587 0 .7833164-.6348039 1.4242116-1.4106754 1.4242116h-6.6403867c-.4232026-1.2461851-1.6222767-2.1363174-2.9976852-2.1363174h-1.4106753c-1.7633443 0-3.1740197 1.4242116-3.1740197 3.2044761s1.4106754 3.2044761 3.1740197 3.2044761h1.4106753c1.3754085 0 2.5392157-.8901322 2.9976852-2.1363174h6.6403867c1.9396787 0 3.5266885-1.602238 3.5266885-3.560529 0-.6052899-.4584695-1.0681587-1.0580065-1.0681587z"
                        fill="#070c63"
                    />
                    <path
                        d="m17.0497777 30.6288946h-1.4106753c-.5995371 0-1.0580066-.4628688-1.0580066-1.0681587s.4584695-1.0681587 1.0580066-1.0681587h1.4106753c.5995371 0 1.0580066.4628688 1.0580066 1.0681587s-.4584695 1.0681587-1.0580066 1.0681587z"
                        fill="#fff"
                    />
                </g>
            )}
        />

    );
}

export default IconCustomerSupport;
