import React from 'react';
import IconUserOutline from "@Icons/IconUserOutline";
import {PassengersTypeType} from "@Form/types/PassengersType.type";
import CountControl from "@/components/CountControl";
import useHandlePassenger from "@Form/features/passengers/hooks/useHandlePassenger";
import {FormVersionType} from "@Form/types/FormVersion.type";
import getFormVersionClassNameAggregator from "@Form/functions/getFormVersionClassNameAggregator";
import Row from "@Containers/components/Row";

interface PassengerGroupProps {
    passengerType: PassengersTypeType,
    fieldName: string,
    formVersion?: FormVersionType,
    count: number,
}

function PassengerGroup(props: PassengerGroupProps) {
    const {
        passengerType,
        fieldName,
        count,
        formVersion = "1",
    } = props;

    const {
        incrementCount,
        decrementCount,
    } = useHandlePassenger(passengerType)

    const {v} = getFormVersionClassNameAggregator(formVersion)

    return (
        <Row
            className={`gb--search${v()}__select--pax-add`}
            justify
            center
        >
            <IconUserOutline
                width="20"
                style={{marginLeft: '1.5rem'}}
            />
            <input
                type="text"
                name={fieldName}
                placeholder={fieldName}
                value={`${fieldName}`}
                readOnly
                disabled
            />
            <CountControl
                handleIncrement={incrementCount}
                incrementClassName="pax--counter gb--row gb--row-middle"
                handleDecrement={decrementCount}
                decrementClassName="pax--counter gb--row gb--row-middle"
                count={count}
            />
        </Row>
    );
}

export default PassengerGroup;
