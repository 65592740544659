import Row from "@Containers/components/Row";
import Translation from "@Translation/components/Translation";
import IconEcoFriendlyTravelChoice from "@Icons/FilledOutline/IconEcoFriendlyTravelChoice";
import IconCustomerSupport from "@Icons/FilledOutline/IconCustomerSupport";
import IconLiveTimetables from "@Icons/FilledOutline/IconLiveTimetable";
import useIsMobile from "@Hooks/selector/useIsMobile";

function OurValuesCta() {
    const isMobile = useIsMobile()
    return (
        <div className="gb--search-2-form__post-content">
            <Row center gap className="gb--search-2-form__post-content_cta">
                <IconEcoFriendlyTravelChoice/>
                <Translation
                    translationKey="sts_page.pre_heading_cta.eco_friendly"
                />
            </Row>
            <Row center gap className="gb--search-2-form__post-content_cta">
                <IconLiveTimetables/>
                <Translation
                    translationKey="sts_page.pre_heading_cta.live_timetables"
                />
            </Row>
            <Row center gap className="gb--search-2-form__post-content_cta">
                <IconCustomerSupport/>
                <Translation
                    translationKey="sts_page.pre_heading_cta.customer_support"
                />
            </Row>
            {!isMobile && (
                <div className="gb--search-2-form__post-content_travel-made-easy">
                    TRAVEL MADE EASY
                </div>
            )}
        </div>
    )
}

export default OurValuesCta