import React, {useState} from "react"
import {useTranslation} from "@Translation/hooks/useTranslation"
import {isNotEmpty} from "@Array/isNotEmpty"
import Modal from "@Generic/Modal/Modal"
import IconTimeAlert from "@Icons/IconTimeAlert"
import {useAppSelector} from "@Hooks/selector/useAppSelector"
import PaymentPendingModal from "@CheckOrder/components/Modals/PaymentPendingModal"
import useGoBack from "@Routing/hooks/useGoBack"
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch"
import {
    setCheckOrderFatalError, setCheckOrderUserMessages,
    setPassengerDataUpdating,
    showCheckOrderModals
} from "@CheckOrder/reducers/checkOrderSlice"
import PriceChangeModal from "@CheckOrder/components/Modals/PriceChangeModal";

function CheckOrderModals() {
    const {
        checkOrderModalsVisible: modalActive,
    } = useAppSelector((state) => state.checkOrder)
    const dispatch = useAppDispatch()

    const setModalActive = (visible: boolean) => {
        dispatch(showCheckOrderModals(visible))
    }

    const {goBack} = useGoBack()
    const {
        ticket,
        checkOrderUserMessages,
        errorOccurred,
        ticketExpired,
        paymentPendingLoader,
    } = useAppSelector((state) => state?.checkOrder)

    const {
        ticket_expired_modal_message: ticketExpiredMessage,
        ticket_expired_button_text: ticketExpiredButtonLabel,
        purchase_expired_modal_title: ticketExpiredTitle,
        there_has_been_an_error: errorNotice,
    } = useTranslation("check_order")

    const handleError = (condition) => {
        if (condition) {
            setModalActive(false)
            dispatch(setCheckOrderFatalError(false))
            dispatch(setPassengerDataUpdating(false))
            dispatch(setCheckOrderUserMessages([]))
        } else {
            goBack()
        }
    }

    if (paymentPendingLoader) {
        return (
            <PaymentPendingModal />
        )
    }

    if (errorOccurred && !ticket) {
        return (
            <Modal
                message="An unexpected error occurred"
                buttonText="OK"
                title={errorNotice}
                handleClick={goBack}
            />
        )
    }

    // TODO: enable pending ticket modal ?
    // if (!!activeTicket) {
    //     return <PendingTicketModal/>
    // }

    if (isNotEmpty(checkOrderUserMessages) && !ticketExpired && modalActive) {
        const {0: {error: {userMessage, deliveryType, okBtnText, messageTitle}, additionalData}} = checkOrderUserMessages
        const handleClick = () => {
            if (deliveryType === "reload_notice") {
                window.location.reload()
            } else {
                handleError(deliveryType === "alert")
            }
        }

        if (additionalData?.isPriceChanged) {
            return (
                <PriceChangeModal
                    isInnerHtml
                    message={userMessage}
                    buttonText={okBtnText}
                    title={messageTitle}
                    handleClick={handleClick}
                    oldPrice={additionalData?.submittedPrice}
                    newPrice={additionalData?.newPrice}
                    passengersNum={additionalData?.passengers}
                />
            )
        }

        return (
            <Modal
                isInnerHtml
                message={userMessage}
                buttonText={okBtnText ?? "OK"}
                title={messageTitle ?? errorNotice}
                handleClick={handleClick}
            />
        )
    }

    if (ticketExpired) {
        return (
            <Modal
                noticeType="warning"
                message={ticketExpiredMessage}
                titleIcon={<IconTimeAlert />}
                closeAction={goBack}
                closeIcon="#refresh"
                buttonText={ticketExpiredButtonLabel}
                title={ticketExpiredTitle}
                handleClick={goBack}
            />
        )
    }

    if (errorOccurred) {
        return (
            <Modal
                message="An unexpected error occurred"
                buttonText="OK"
                title={errorNotice}
                handleClick={() => handleError(true)}
                isInnerHtml
            />
        )
    }

    return null
}

export default CheckOrderModals