import React from 'react';
import GenericSvgIcon from "../GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconLiveTimetables(props: IconType) {
    const {
        width = '40',
        height = '40',
        viewBox = '0 0 40 40',
        title = 'IconLiveTimetables',
        ...rest
    } = props
    return (
        <GenericSvgIcon
            {...{width, height, viewBox, title, ...rest}}
            svgIconPath={(
                <g fill="none" fillRule="evenodd">
                    <path d="m3.886225 11.71169h35v26.769627h-35z" fill="#fff"/>
                    <ellipse cx="12" cy="12.04631" fill="#fff" rx="10" ry="10.03861"/>
                    <ellipse
                        cx="11.414628"
                        cy="11.592137"
                        fill="#4a79e2"
                        fillOpacity=".25"
                        rx="5.861736"
                        ry="5.736304"
                    />
                    <g fillRule="nonzero">
                        <path
                            d="m2.92307692.46332046c-.76923077-.61776061-1.84615384-.61776061-2.46153846 0-.61538461.61776062-.61538461 1.6988417 0 2.47104247.61538462.61776062 1.69230769.61776062 2.46153846 0 .61538462-.77220077.61538462-1.85328185 0-2.47104247z"
                            fill="#4a79e2"
                            transform="translate(9.6923 9.8841)"
                        />
                        <path
                            d="m.23076923 5.89261784c-.30769231-.30888031-.30769231-.92664092 0-1.23552123l4.76923077-4.47876448c.15384615-.15444015.46153846-.30888031.92307692 0 .30769231.30888031.15384616.77220077 0 .92664093l-4.3076923 4.94208494c-.30769231.30888031-.92307693.30888031-1.38461539-.15444016z"
                            fill="#4a79e2"
                            transform="translate(10.5385 6.1537)"
                        />
                        <path
                            d="m1.10083347 0c.76923077 0 1.23076923.30888031 1.23076923.77220077l-.30769231 5.25096525c0 .15444016-.30769231.61776062-.76923077.61776062-.61538462 0-.76923077-.30888031-.76923077-.61776062l-.46153846-5.25096525c-.15384616-.46332046.46153846-.77220077 1.07692308-.77220077z"
                            fill="#4a79e2"
                            transform="translate(10.2838 10.8108)"
                        />
                        <path
                            d="m5.69230769 3.08880309c.15384616 0 .15384616 0 .30769231.15444015 0 0 .15384615.15444016.15384615.30888031 0 .15444016.15384616 2.93436294-.3076923 5.0965251 0 .15444015-.15384616.30888031-.30769231.30888031-.15384616 0-.30769231 0-.46153846-.15444016l-1.23076923-1.08108108s-1.38461539 1.08108108-1.38461539 3.86100388c-1.53846154.4633204-2.30769231 0-2.30769231 0 0-3.86100388 1.53846154-6.17760619 1.53846154-6.17760619l-1.38461538-1.23552124c-.15384616 0-.15384616-.30888031-.15384616-.46332046 0-.15444016.15384616-.30888031.30769231-.30888031 2.46153846-.46332047 5.07692308-.30888031 5.23076923-.30888031z"
                            fill="#070c63"
                        />
                        <path
                            d="m30.7692308 10.8108108c-.923077 0-1.5384616-.6177606-1.5384616-1.54440153v-4.63320464c0-.92664092.6153846-1.54440154 1.5384616-1.54440154.9230769 0 1.5384615.61776062 1.5384615 1.54440154v4.63320464c0 .92664093-.6153846 1.54440153-1.5384615 1.54440153z"
                            fill="#777aaa"
                        />
                        <path
                            d="m5.69230769 1.54440154c.61538462 0 1.07692308.30888031 1.38461539.61776062.3076923.30888031.46153846.77220077.61538461 1.23552124 1.23076923-.61776062 2.46153851-.92664093 3.84615381-.92664093 4.923077 0 9.0769231 4.01544402 9.0769231 9.11196913 0 4.9420849-4 9.1119691-9.0769231 9.1119691-4.92307688 0-9.07692304-4.015444-9.07692304-9.1119691 0-1.3899614.30769231-2.62548264.76923077-3.70656372l-1.84615385-1.6988417c-.92307692 1.54440154-1.38461538 3.3976834-1.38461538 5.40540542 0 6.3320463 5.23076923 11.5830116 11.5384615 11.5830116 6.3076923 0 11.5384616-5.2509653 11.5384616-11.5830116 0-6.33204635-5.2307693-11.5830116-11.5384616-11.5830116-2.15384612 0-4.15384612.61776062-5.84615381 1.54440154z"
                            fill="#070c63"
                        />
                        <g transform="translate(3.0769 6.1776)">
                            <g fill="#4a79e2">
                                <rect height="3.088803" rx=".769231" width="3.076923" x="13.846154" y="24.710425"/>
                                <rect height="3.088803" rx=".769231" width="3.076923" x="7.692308" y="18.532819"/>
                                <rect height="3.088803" rx=".769231" width="3.076923" x="7.692308" y="24.710425"/>
                                <rect height="3.088803" rx=".769231" width="3.076923" x="20" y="18.532819"/>
                                <rect height="3.088803" rx=".769231" width="3.076923" x="20" y="24.710425"/>
                                <rect height="3.088803" rx=".769231" width="3.076923" x="20" y="12.355212"/>
                            </g>
                            <path
                                d="m32.3076923 0h-1.5384615v3.08880309c0 1.6988417-1.3846154 3.08880309-3.0769231 3.08880309s-3.0769231-1.38996139-3.0769231-3.08880309v-3.08880309h-4.3076923c.7692308 1.6988417 1.2307692 3.55212355 1.2307692 5.40540541 0 1.38996139-.1538461 2.62548262-.6153846 3.86100386h13.6923077v20.07722003c0 .926641-1.3846154 2.3166024-2.3076923 2.3166024h-27.69230768c-.92307693 0-2.30769231-1.3899614-2.30769231-2.3166024v-12.046332c-1.07692308-.4633205-1.38461539-1.2355212-2.30769231-2.007722v13.8996139c0 2.6254826 2 4.6332046 4.61538462 4.6332046h27.69230768c2.6153846 0 4.6153846-2.007722 4.6153846-4.6332046v-24.55598457c0-2.62548262-2-4.63320463-4.6153846-4.63320463z"
                                fill="#070c63"
                            />
                            <rect
                                fill="#4a79e2"
                                height="3.088803"
                                rx=".769231"
                                width="3.076923"
                                x="13.846154"
                                y="18.532819"
                            />
                            <rect
                                fill="#4a79e2"
                                height="3.088803"
                                rx=".769231"
                                width="3.076923"
                                x="26.153846"
                                y="12.355212"
                            />
                            <rect
                                fill="#4a79e2"
                                height="3.088803"
                                rx=".769231"
                                width="3.076923"
                                x="26.153846"
                                y="24.710425"
                            />
                            <rect
                                fill="#4a79e2"
                                height="3.088803"
                                rx=".769231"
                                width="3.076923"
                                x="26.153846"
                                y="18.532819"
                            />
                        </g>
                    </g>
                </g>
            )}
        />

    );
}

export default IconLiveTimetables;
