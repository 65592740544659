import {ReactNode} from "react";
import useFormMethods from "@Form/hooks/useFormMethods";
import RouteDescription from "@Pages/stationToStation/features/header/components/RouteDescription";
import Translation from "@Translation/components/Translation";
import useIsMobile from "@Hooks/selector/useIsMobile";

interface FormWrapperProps {
    formVersion?: string
    children: ReactNode,
}

function FormWrapper({formVersion = "1", children}: FormWrapperProps) {
    const {
        setClassName,
    } = useFormMethods()

    const isMobile = useIsMobile()

    if (formVersion === "2") {
        return (
            <div className="gb--search-2-container gb--search-2-container__outer">
                <RouteDescription/>
                <div className="gb--search-2-container gb--search-2-container__inner">
                    {!isMobile && (
                        <p className="gb--search-2-container__inner-cta">
                            <Translation translationKey="sts_page.form_title"/>
                        </p>
                    )}
                    {children}
                </div>
            </div>
        )
    }

    return (
        <div className={setClassName()}>
            {children}
        </div>
    )
}

export default FormWrapper