import useExperiments from "@Hooks/selector/useExperiments";
import {FormVersionType} from "@Form/types/FormVersion.type";
import {isPort2PortOrStation2StationRoute} from "@Routing/functions/isPort2PortOrStation2StationRoute";
import rootExtractor from "@Dataset/rootExtractor";
import LocationToLocationCountryImageType
    from "@Pages/stationToStation/features/header/types/LocationToLocationCountryImage.type";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useIsTablet from "@Hooks/selector/useIsTablet";
import useIsDesktop from "@Hooks/selector/useIsDesktop";

/**
 * indicates which form version should be used.
 * used as a part of GBW-4146 experiment (only) on S2S and P2P pages
 */
export default function useFormVersion(): FormVersionType {
    const {searchFormStationToStationPages} = useExperiments()
    const formVersionFromExperiment = searchFormStationToStationPages ? "2" : "1"

    const isMobile = useIsMobile()
    const isTablet = useIsTablet()
    const isDesktop = useIsDesktop()

    // const {reverseDirectionUrl} = window

    const locationToLocationCountryImageJsonRaw = rootExtractor('locationToLocationCountryImage')
    const locationToLocationCountryImage = locationToLocationCountryImageJsonRaw
        ? JSON.parse(locationToLocationCountryImageJsonRaw) as LocationToLocationCountryImageType
        : null

    const hasImage = !!locationToLocationCountryImage && (
        (locationToLocationCountryImage.pathMobile && isMobile)
        || (locationToLocationCountryImage.pathTablet && isTablet)
        || (locationToLocationCountryImage.pathWindow && isDesktop)
    )

    if (isPort2PortOrStation2StationRoute()) {
        if (!hasImage) {
            return "1"
        }

        return formVersionFromExperiment
    }

    return "1"
}