import {useEffect} from "react";
import permalinkTypeId from "@/constants/PermalinkTypeId";
import formatDate from "@DateTime/functions/formatDate";
import {setDate} from "@Form/reducers/formSlice";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import useIsMobile from "@Hooks/selector/useIsMobile";
import {dateFormat} from "@Form/features/dates/constants/dateFormat";

export default function usePrefillDateOnStsPages(date: string | null) {
    const {
        mobileStsSearchDatePrefill,
    } = useAppSelector((state) => (state?.page.experimentsJson));

    const dispatch = useAppDispatch();
    const isMobile = useIsMobile()

    useEffect(() => {
        if (
            mobileStsSearchDatePrefill
            && isMobile
            && !date
            && (window?.permalinkTypeId === permalinkTypeId.portToPort || window?.permalinkTypeId === permalinkTypeId.stationToStation)
        ) {
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            dispatch(setDate(formatDate(tomorrow, dateFormat)));
        }
    }, [isMobile]);
}