import React from 'react';
import Translation from "@Translation/components/Translation";
import stripTags from "@Form/functions/stripTags";
import {FormVersionType} from "@Form/types/FormVersion.type";
import getFormVersionClassNameAggregator from "@Form/functions/getFormVersionClassNameAggregator";

interface PopularDestinationsNoticeProps {
    stationLabel?: string,
    formVersion?: FormVersionType,
}

const PopularDestinationsNotice = ({stationLabel, formVersion = "1"}: PopularDestinationsNoticeProps) => {
    const {v} = getFormVersionClassNameAggregator(formVersion)

    return (
        <div className={`gb--search${v()}__select--popular-destinations`}>
            <Translation translationKey="succ.popular_destinations_from"/>
            {' '}
            {stripTags(stationLabel)}
        </div>
    );
}

export default PopularDestinationsNotice;