import {useLayoutEffect} from "react";
import {useNavigationType} from "react-router";
import useSearchParamsExtractedFromRoute from "@SearchResults/hooks/useSearchParamsExtractedFromRoute";
import useSetDate from "@Form/hooks/useSetDate";
import displayedDate from "@Form/features/dates/functions/displayedDate";
import useIsMobile from "@Hooks/selector/useIsMobile";

/**
 * Fixes date picker not changing on "POP"
 */
export default function useUpdateDateFromSearchParams(date: string, returnDate: string) {
    const isMobile = useIsMobile()
    const navType = useNavigationType();

    const {
        date: routeProvidedDate,
        returnDate: routeProvidedReturnDate,
    } = useSearchParamsExtractedFromRoute()

    const changeSingle = useSetDate()
    const changeReturn = useSetDate('return')

    useLayoutEffect(() => {
        if (navType === "POP" && !isMobile) {
            if (routeProvidedDate !== date) {
                changeSingle(displayedDate(routeProvidedDate))
            }
            if (routeProvidedReturnDate !== returnDate) {
                changeReturn(displayedDate(routeProvidedReturnDate))
            }
        }
    }, [routeProvidedDate, routeProvidedReturnDate]);
}