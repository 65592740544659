import React from 'react';
import PassengerPicker from "./PassengerPicker";
import useQuantityAwareTranslation from "@Translation/hooks/useQuantityAwareTranslation";
import usePassengers from "@Hooks/selector/usePassengers";
import useTogglePassengerPicker from "../hooks/useTogglePassengerPicker";
import IconPassengersAdd from "@Icons/IconPassengersAdd";
import Translation from "@Translation/components/Translation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {FormVersionType} from "@Form/types/FormVersion.type";
import getFormVersionClassNameAggregator from "@Form/functions/getFormVersionClassNameAggregator";

interface PassengerPickerButtonProps {
    formVersion?: FormVersionType,
}

function PassengerPickerButton({formVersion = "1"}: PassengerPickerButtonProps) {
    const passengerPickerActive = useAppSelector((state) => state?.form.passengerPickerActive)
    const {passengers} = usePassengers()
    const label = useQuantityAwareTranslation("sys.passenger", passengers)
    const onClick = useTogglePassengerPicker()

    const {v} = getFormVersionClassNameAggregator(formVersion)

    return (
        <div className={`gb--search${v()}__select gb--search${v()}__select--pax`}>
            <span className={`gb--search${v()}__select--label gb--emphasize gb--emphasize-default`}>
                <Translation translationKey="sys.passengers"/>
            </span>
            <IconPassengersAdd
                className={`gb--search${v()}__select--icon`}
            />
            <input
                type="text"
                name="passengers"
                className="gb--input-standalone"
                placeholder={label}
                value={`${passengers} ${label}`}
                onClick={onClick}
                readOnly
            />
            {passengerPickerActive && (
                <PassengerPicker
                    formVersion={formVersion}
                />
            )}
        </div>
    );
}

export default PassengerPickerButton;