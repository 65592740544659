import RouteType from "@Route/types/Route.type";
import getRouteDateTime from "@Route/functions/getRouteDateTime";
import isSameRouteBasedOnTravelTimes from "@Route/functions/isSameRouteBasedOnTravelTimes";
import {nomagoCompanyId, samoborcekCompanyId} from "@SearchResults/api/constants/companyIds";

/**
 * Filters API routes by departure and arrival time
 * If there is non-API route with same departure and arrival time, show only non-API route
 */
export default function filterDuplicateApiRoutes(allRoutes: RouteType[]) {
    const getDepartureAndDestinationTimeString = (route: RouteType) => (
        `${getRouteDateTime({route, type: "departure"})}-${getRouteDateTime({route, type: "arrival"})}`
    )

    const isNomagoRouteMatchingSamoborcekRoute = (route: RouteType) => (
        allRoutes.find((routeToCompare) => (
            routeToCompare.companyId === samoborcekCompanyId && isSameRouteBasedOnTravelTimes(route, routeToCompare)
        ))
    )

    // Get all departure and destination times for non-api routes
    const routeTimes = allRoutes.map((route) => {
        if (!route.status.isApi && !route.status.isApiFullIntegration) {
            return getDepartureAndDestinationTimeString(route)
        }
    }).filter((item) => item)

    return allRoutes.filter((route) => {
        if (!route.status.isApi && !route.status.isApiFullIntegration) {
            return route
        }

        if (!routeTimes.includes(getDepartureAndDestinationTimeString(route))) {
            return route
        }

        // GBW-4168 -> nomago-samoborcek logic exclusion
        if (route.companyId === nomagoCompanyId && isNomagoRouteMatchingSamoborcekRoute(route)) {
            return route
        }
    })
}