import {isCapitalized} from "@String/isCapitalized";

/**
 * Truncates names
 *
 * takes a name (string) and IF necessary replaces as little capitalized words as possible with initial (e.g. Stipe => S.)
 * if it is possible keeps the un-capitalized words.
 * if all the capitalized words are turned into initials, and complete name is still longer than limit, removes un-capitalized words
 *
 * e.g. "Eduardo Alves da Silva"
 *      limit = 16 => "Eduardo A. da S."
 *      limit = 15 => "E. A. da S."
 *      limit = 10 => "E. A. S."
 */
export default function truncateName(name: string, limit: number) {
    if (name.length <= limit) {
        return name
    }

    const names = name.split(" ")
    const initials = []
    let i

    for (i = names.length - 1; i >= 0; i--) {
        const word = names[i]

        if (isCapitalized(word)) {
            if (initials.join(" ").length + names.slice(0, i + 1).join(" ").length > limit) {
                initials.unshift(`${word.charAt(0)}.`);
            } else {
                break;
            }
        } else {
            initials.unshift(word)
        }
    }

    const completeNameWithInitials = `${names.slice(0, i + 1).join(" ")} ${initials.join(" ")}`

    if (completeNameWithInitials.length <= limit) {
        return completeNameWithInitials
    }

    const justInitials = completeNameWithInitials.split(" ").filter((word) => (/^[A-Z]/.test(word)))

    return justInitials.join(" ")
}