import {FormVersionType} from "@Form/types/FormVersion.type";
import HeroBackground from "@Form/components/HeroBackground";
import React from "react";

interface FormBackgroundProps {
    formVersion?: FormVersionType
}

function FormBackground({formVersion = "1"}: FormBackgroundProps) {
    if (formVersion === "2") {
        return (
            <></>
        )
    }

    return (
        <HeroBackground/>
    )
}

export default FormBackground