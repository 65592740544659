export default {
    flip: {
        behavior: ["bottom"],
    },
    preventOverflow: {
        enabled: false,
    },
    hide: {
        enabled: false,
    },
}