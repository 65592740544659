import ApiProviderType from "@SearchResults/types/ApiProvider/ApiProvider.type";
import {useEffect} from "react";
import {useGetApiSearchResultsMutation} from "@SearchResults/api/searchResultsApi";
import useApiServiceFetcher from "@SearchResults/api/hooks/useApiServiceFetcher";
import SearchResultsRequestType from "@SearchResults/api/types/SearchResultsRequest.type";
import useSearchParamsExtractedFromRoute from "@SearchResults/hooks/useSearchParamsExtractedFromRoute";
import {SearchResultsRtkQueryResponseType} from "@SearchResults/api/types/SearchResultsRtkQueryResponse.type";
import usePassengers from "@Hooks/selector/usePassengers"

export default function useApiRoutesFetcher(data: SearchResultsRtkQueryResponseType) {
    const baseSearchParams = useSearchParamsExtractedFromRoute()
    const [method, apiResults]: any = useGetApiSearchResultsMutation()
    const passengers = usePassengers()

    const {fetchFromApiService} = useApiServiceFetcher()

    const fetchApiRoutesApiMethod = (provider: ApiProviderType, searchParams: SearchResultsRequestType, isReturn: boolean) => {
        const fromSplit = searchParams?.fromSlug.split("-")
        const toSplit = searchParams?.toSlug.split("-")
        const departingStationId = fromSplit[fromSplit.length - 1]
        const destinationStationId = toSplit[toSplit.length - 1]
        const params = {
            isReturn,
            searchParams: baseSearchParams,
            apiIntegrationId: provider.apiIntegrationId,
            apiKey: "API_SEARCH_RESULTS_KEY",
            date: isReturn ? searchParams?.returnDate : searchParams.date,
            departingStationId: isReturn ? destinationStationId : departingStationId,
            destinationStationId: isReturn ? departingStationId : destinationStationId,
            pax: passengers.passengers,
        }

        return {
            params,
            method,
        }
    }

    const fetchApiRoutes = (provider: ApiProviderType, searchParams: SearchResultsRequestType, isReturn: boolean) => {
        provider.apiStationCombinations.map(({departureIds, destinationIds}) => {
            const {
                method,
                params,
            } = fetchApiRoutesApiMethod(provider, searchParams, isReturn)

            const paramsWithCombinations = {
                ...params,
                departingApiStations: isReturn ? destinationIds : departureIds,
                destinationApiStations: isReturn ? departureIds : destinationIds,
            }

            fetchFromApiService({
                method,
                params: paramsWithCombinations,
            })
        })
    }

    useEffect(() => {
        if (data?.result.availableSingleApiProviders && data?.result.params) {
            data.result.availableSingleApiProviders.map((provider: ApiProviderType) => {
                fetchApiRoutes(provider, {...baseSearchParams, pax: passengers.passengers}, false)
            })
        }
    }, [data?.result?.availableSingleApiProviders])

    useEffect(() => {
        if (data?.result.availableReturnApiProviders && data?.result.params && data?.result?.params?.isReturn) {
            data.result.availableReturnApiProviders.map((provider: ApiProviderType) => {
                fetchApiRoutes(provider, {...baseSearchParams, pax: passengers.passengers}, true)
            })
        }
    }, [data?.result?.availableReturnApiProviders])

    return {
        apiResults,
        fetchApiRoutes,
    }
}