import React from 'react';
import AutoSuggest from "@Features/autosuggest/components/AutoSuggest";
import FormSuggestion from "@Form/components/FormSuggestion";
import useFormStationInputAutoSuggest from "@Form/hooks/useFormStationInputAutoSuggest";
import Translation from "@Translation/components/Translation";
import IconLocationMarker from "@Icons/IconLocationMarker";
import StationSwitchButton from "@Form/components/StationSwitchButton";
import {useTranslation} from "@Translation/hooks/useTranslation";
import PopularDestinationsNotice from "@Form/components/PopularDestinationsNotice";
import useIsMobile from "@Hooks/selector/useIsMobile";
import FormInputError from "@Form/components/FormInputError";
import StationInputFieldType from "@Form/types/StationInputField.type";
import {FormVersionType} from "@Form/types/FormVersion.type";
import getFormVersionClassNameAggregator from "@Form/functions/getFormVersionClassNameAggregator";

interface FormStationInputFieldProps {
    stationObject: StationInputFieldType,
    direction: "departure" | "destination",
    formVersion?: FormVersionType,
}

function FormStationInputField({stationObject, direction, formVersion = "1"}: FormStationInputFieldProps) {
    const autoSuggestModel = useFormStationInputAutoSuggest(direction, stationObject)
    const isMobile = useIsMobile()
    const isDeparture = direction === 'departure'
    const directionLabel = isDeparture ? 'form.depart_from' : 'form.travel_to'
    const className = isDeparture ? '--from' : '--to'
    const placeholder = useTranslation(isDeparture ? "form.departure_station" : "form.destination_station")

    const {v} = getFormVersionClassNameAggregator(formVersion)

    return (
        <div className={`gb--search${v()}__select gb--search${v()}__select${className}`}>
            <span className={`gb--search${v()}__select--label gb--emphasize gb--emphasize-default`}>
                <Translation translationKey={directionLabel}/>
            </span>
            <IconLocationMarker
                className={`gb--search${v()}__select--icon`}
                height="14"
            />
            <AutoSuggest
                autoFocus={isMobile}
                autoSuggestModel={autoSuggestModel}
                placeholder={placeholder}
                suggestionComponent={<FormSuggestion/>}
                decorationComponent={(
                    <PopularDestinationsNotice
                        stationLabel={autoSuggestModel?.updateContext?.label}
                    />
                )}
            />
            <FormInputError
                direction={direction}
            />
            {isDeparture && !isMobile && (
                <StationSwitchButton/>
            )}
        </div>
    );
}

export default FormStationInputField;