import {useAppSelector} from "@Hooks/selector/useAppSelector";
import usePassengers from "@Hooks/selector/usePassengers";

export default function useToggleBusRentNotice() {
    const {
        departureStationObject,
    } = useAppSelector((state) => state?.form);

    const {
        passengers,
    } = usePassengers()

    const minimalNumberOfPassengersToDisplayBusRentForm = departureStationObject?.countryBusRentPaxCountMin

    const showBusRent = !!departureStationObject?.label
        && !!minimalNumberOfPassengersToDisplayBusRentForm
        && passengers >= minimalNumberOfPassengersToDisplayBusRentForm

    return {
        showBusRent,
    }
}