import React from 'react';
import GenericSvgIcon from "../GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconEcoFriendlyTravelChoice(props: IconType) {
    const {
        width = '40',
        height = '40',
        viewBox = '0 0 40 40',
        title = 'IconEcoFriendlyTravelChoice',
        ...rest
    } = props
    return (
        <GenericSvgIcon
            {...{width, height, viewBox, title, ...rest}}
            svgIconPath={(
                <g fill="none" fillRule="evenodd">
                    <g fillRule="nonzero" transform="translate(6)">
                        <path
                            d="m21.1137898 38.9655172h-18.45708777c-.87391514 0-1.60800386-.7241379-1.60800386-1.5862069v-34.75862064c0-.86206897.73408872-1.5862069 1.60800386-1.5862069h18.45708777c.8739151 0 1.6080038.72413793 1.6080038 1.5862069v34.75862064c0 .862069-.7340887 1.5862069-1.6080038 1.5862069"
                            fill="#7ed321"
                        />
                        <path d="m.95081967.94117647h21.67309543v32.94117643h-21.67309543z" fill="#fff"/>
                        <path
                            d="m21.1137898 40h-18.45708777c-1.46817744 0-2.65670203-1.1724138-2.65670203-2.6206897v-34.75862064c0-1.44827587 1.18852459-2.62068966 2.65670203-2.62068966h18.45708777c1.4681774 0 2.656702 1.17241379 2.656702 2.62068966v34.75862064c0 1.4482759-1.1885246 2.6206897-2.656702 2.6206897zm-18.45708777-37.93103448c-.31460946 0-.55930569.24137931-.55930569.55172414v34.75862064c0 .3103449.24469623.5517242.55930569.5517242h18.45708777c.3146094 0 .5593057-.2413793.5593057-.5517242v-34.75862064c0-.31034483-.2446963-.55172414-.5593057-.55172414z"
                            fill="#070c63"
                        />
                        <path
                            d="m15.1711668 5.17241379h-6.4320154c-.5942623 0-1.04869817-.44827586-1.04869817-1.03448276 0-.58620689.45443587-1.03448275 1.04869817-1.03448275h6.3970588c.5942623 0 1.0486982.44827586 1.0486982 1.03448275 0 .5862069-.4544359 1.03448276-1.0137416 1.03448276z"
                            fill="#777aaa"
                        />
                        <path
                            d="m12.1229508 36.9411765c-.6418033 0-1.1885246-.5411765-1.1885246-1.1764706s.5467213-1.1764706 1.1885246-1.1764706 1.1885246.5411765 1.1885246 1.1764706-.5467213 1.1764706-1.1885246 1.1764706z"
                            fill="#fff"
                        />
                    </g>
                    <rect fill="#dff4c8" height="21.647059" rx="2.951872" width="21.868853" x="13.1311" y="9.4118"/>
                    <g transform="translate(16.6102 13.3476)">
                        <path
                            d="m3.44090336 4.54134101h-2.29393557c-.63312622 0-1.14696779-.50863019-1.14696779-1.13533525v-2.27067051c0-.62670506.51384157-1.13533525 1.14696779-1.13533525h2.29393557c.63312622 0 1.14696779.50863019 1.14696779 1.13533525v2.27067051c0 .62670506-.51384157 1.13533525-1.14696779 1.13533525z"
                            stroke="#070c63"
                            strokeWidth="1.135335"
                        />
                        <path
                            d="m3.44090336 14.7593583h-2.29393557c-.63312622 0-1.14696779-.5086302-1.14696779-1.1353353v-2.2706705c0-.626705.51384157-1.1353352 1.14696779-1.1353352h2.29393557c.63312622 0 1.14696779.5086302 1.14696779 1.1353352v2.2706705c0 .6267051-.51384157 1.1353353-1.14696779 1.1353353z"
                            stroke="#070c63"
                            strokeWidth="1.135335"
                        />
                        <path
                            d="m13.7636134 4.54134101h-2.2939355c-.6331263 0-1.1469678-.50863019-1.1469678-1.13533525v-2.27067051c0-.62670506.5138415-1.13533525 1.1469678-1.13533525h2.2939355c.6331263 0 1.1469678.50863019 1.1469678 1.13533525v2.27067051c0 .62670506-.5138415 1.13533525-1.1469678 1.13533525z"
                            stroke="#070c63"
                            strokeWidth="1.135335"
                        />
                        <g fill="#070c63" fillRule="nonzero">
                            <path d="m1.720452 1.703003h1.146968v1.135335h-1.146968z"/>
                            <path d="m1.720452 5.109009h1.146968v1.135335h-1.146968z"/>
                            <path d="m2.867419 6.244344h1.146968v1.135335h-1.146968z"/>
                            <path d="m1.720452 7.379679h1.146968v1.135335h-1.146968z"/>
                            <path d="m8.602258 6.244344h1.146968v1.135335h-1.146968z"/>
                            <path d="m12.043162 5.109009h1.146968v1.135335h-1.146968z"/>
                            <path d="m13.19013 6.244344h1.146968v1.135335h-1.146968z"/>
                            <path d="m12.043162 7.379679h1.146968v1.135335h-1.146968z"/>
                            <path d="m10.896194 8.515014h1.146968v1.135335h-1.146968z"/>
                            <path d="m13.19013 8.515014h1.146968v1.135335h-1.146968z"/>
                            <path d="m12.043162 9.65035h1.146968v1.135335h-1.146968z"/>
                            <path d="m12.043162 11.92102h1.146968v1.135335h-1.146968z"/>
                            <path d="m13.19013 13.056355h1.146968v1.135335h-1.146968z"/>
                            <path d="m10.896194 13.056355h1.146968v1.135335h-1.146968z"/>
                            <path d="m9.749226 11.92102h1.146968v1.135335h-1.146968z"/>
                            <path d="m6.308323 1.703003h1.146968v1.135335h-1.146968z"/>
                            <path d="m6.308323 10.785685h1.146968v3.406006h-1.146968z"/>
                            <path d="m6.308323 7.379679h1.146968v1.135335h-1.146968z"/>
                            <path d="m7.455291.567668h1.146968v3.406006h-1.146968z"/>
                            <path d="m5.161355 8.515014h4.587871v1.135335h-4.587871z"/>
                            <path d="m5.161355 5.109009h4.587871v1.135335h-4.587871z"/>
                            <path d="m.573484 6.244344h1.146968v1.135335h-1.146968z"/>
                            <path d="m1.720452 11.92102h1.146968v1.135335h-1.146968z"/>
                            <path d="m12.043162 1.703003h1.146968v1.135335h-1.146968z"/>
                        </g>
                    </g>
                </g>
            )}
        />

    );
}

export default IconEcoFriendlyTravelChoice;
