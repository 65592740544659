import {PassengersTypeType} from "@Form/types/PassengersType.type";
import {addPassenger, removePassenger} from "@Form/reducers/formSlice";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

export default function useHandlePassenger(passengerType: PassengersTypeType) {
    const dispatch = useAppDispatch()

    const handlePassenger = (type: PassengersTypeType, action: "add" | "remove") => {
        if (action === 'add') {
            dispatch(addPassenger(type))
        } else {
            dispatch(removePassenger(type))
        }
    };

    const incrementCount = () => {
        handlePassenger(passengerType, "add")
    }

    const decrementCount = () => {
        handlePassenger(passengerType, "remove")
    }

    return {
        incrementCount,
        decrementCount,
    }
}