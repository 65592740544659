import React, {useLayoutEffect} from "react"
import FormStationInputField from "@Form/components/FormStationInputField"
import {useAppSelector} from "@Hooks/selector/useAppSelector"
import {useNavigationType} from "react-router"
import useSearchResults from "@SearchResults/hooks/useSearchResults"
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch"
import {setDepartureStationObject, setDestinationStationObject} from "@Form/reducers/formSlice"
import {FormVersionType} from "@Form/types/FormVersion.type";

interface StationInputFieldsProps {
    formVersion?: FormVersionType,
}

function StationInputFields({formVersion = "1"}: StationInputFieldsProps) {
    const {
        departureStationObject,
        destinationStationObject,
    } = useAppSelector((state) => state?.form)

    const navType = useNavigationType()
    const {
        data,
    } = useSearchResults()
    const dispatch = useAppDispatch()

    useLayoutEffect(() => {
        if (data && navType === "POP") {
            if (departureStationObject.id !== data.result.params.departureStation.id) {
                dispatch(setDepartureStationObject({
                    value: data.result.params.departureStation.id,
                    label: data.result.params.departureStation.name,
                    slug: data.result.params.departureStation.slug,
                }))
            }
        }
    }, [data?.result?.params?.departureStation?.id])

    useLayoutEffect(() => {
        if (data && navType === "POP") {
            if (destinationStationObject.id !== data.result.params.departureStation.id) {
                dispatch(setDestinationStationObject({
                    value: data.result.params.destinationStation.id,
                    label: data.result.params.destinationStation.name,
                    slug: data.result.params.destinationStation.slug,
                }))
            }
        }
    }, [data?.result?.params?.destinationStation?.id])

    return (
        <>
            <FormStationInputField
                direction="departure"
                stationObject={departureStationObject}
                formVersion={formVersion}
            />
            <FormStationInputField
                direction="destination"
                stationObject={destinationStationObject}
                formVersion={formVersion}
            />
        </>
    )
}

export default StationInputFields