import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

interface IconArrowSwitchProps extends IconType {
    outline?: string,
}

function IconArrowSwitch(props: IconArrowSwitchProps) {
    const {
        width = '45',
        height = '40',
        viewBox = '0 0 45 40',
        fill = '#fff',
        outline = '#070c63',
        title = 'IconArrowSwitch',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, fill, title, ...rest}}
            svgIconPath={(
                <g fill="none" fillRule="evenodd">
                    <rect fill={fill} height="39.854" rx="9.6" width="45"/>
                    <g fill={outline} fillRule="nonzero" transform="translate(11 7)">
                        <path
                            d="m6.93949169.38484733-.06460234.06368909-4.95809251 5.47986424c-.22180734.22158735-.3302565.54848361-.3302565.90979519 0 .30109299.07531192.57828645.24384981.80643729l5.04419385 5.58288446c.27420565.2768177.59955315.3857831.95915291.3857831s.68494725-.1089654.93280107-.3579991c.48418767-.4864925.56611052-1.1449952.15503541-1.6957045l-.11896436-.1400391-2.96083537-3.28691858 15.87462224.00079707c.7686642 0 1.2891041-.5229174 1.2891041-1.29524064 0-.65589186-.380757-1.16598456-1.027146-1.2742289l-.2011151-.01866395-15.93546524-.00314485 2.95188836-3.27522472c.53058148-.53172067.53058148-1.28548295-.02712404-1.84584334-.48418767-.48649257-1.13957051-.56880541-1.68767066-.15577344z"
                            transform="matrix(-1 0 0 1 24.592 0)"
                        />
                        <path
                            d="m5.35295135 13.1381273-.06460234.0636891-4.95809251 5.4798643c-.22180733.2215873-.3302565.5484836-.3302565.9097952 0 .3010929.07531192.5782864.24384982.8064372l5.04419384 5.5828845c.27420565.2768177.59955315.3857831.95915291.3857831s.68494725-.1089654.93280107-.3579991c.48418767-.4864925.56611052-1.1449952.15503541-1.6957045l-.11896436-.1400391-2.96083537-3.2869186 15.87462228.0007971c.7686641 0 1.2891041-.5229174 1.2891041-1.2952406 0-.6558919-.380757-1.1659846-1.027146-1.2742289l-.2011152-.018664-15.93546518-.0031449 2.95188836-3.2752247c.53058148-.5317206.53058148-1.2854829-.02712404-1.8458433-.48418767-.4864926-1.13957051-.5688054-1.68767066-.1557734z"
                        />
                    </g>
                </g>
            )}
        />

    );
}

export default IconArrowSwitch;
