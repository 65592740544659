import React, {ReactNode} from 'react';
import usePushNewUrl from "@Routing/hooks/usePushNewUrl";
import useScrollToFormAndAnimate from "@Form/hooks/useScrollToFormAndAnimate";
import googleTagManagerSearchEvent from "@Form/functions/googleTagManager";
import useValidateForm from "../hooks/useValidateForm";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {FormVersionType} from "@Form/types/FormVersion.type";
import getFormVersionClassNameAggregator from "@Form/functions/getFormVersionClassNameAggregator";

interface FormContainerProps {
    children: ReactNode,
    formVersion?: FormVersionType,
}

function FormContainer({children, formVersion = "1"}: FormContainerProps) {
    const {checkForErrors} = useValidateForm()
    const pushNewUrl = usePushNewUrl()
    const {formAnimationActive} = useScrollToFormAndAnimate()
    const {urlParams} = useAppSelector((state) => (state?.form));

    const {v} = getFormVersionClassNameAggregator(formVersion)

    const validateAndPushUrl = () => {
        if (checkForErrors()) {
            pushNewUrl(urlParams);
        }
    }

    const submitForm = (e) => {
        e.preventDefault();
        googleTagManagerSearchEvent(urlParams);
        validateAndPushUrl()
    };

    return (
        <form
            className={`gb--search${v()}-form ${formAnimationActive ? 'animate--wiggle' : ''}`}
            onSubmit={(e) => submitForm(e)}
        >
            {children}
        </form>
    );
}

export default FormContainer;