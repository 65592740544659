import Form from "@Form/components/Form";
import FormV2Background from "@Form/components/FormV2Background";
import OurValuesCta from "@Pages/stationToStation/features/header/components/OurValuesCta";
import useFormVersion from "@Form/hooks/useFormVersion";

function StsPageHeader() {
    const formVersion = useFormVersion()

    if (formVersion === "1") {
        return <Form/>
    }

    return (
        <section className="gb--search-2">
            <FormV2Background/>
            <div className="gb--search-2-wrapper">
                <Form formVersion="2"/>
            </div>
            <OurValuesCta/>
        </section>
    )
}

export default StsPageHeader