import {toggleMobilePassengerPicker} from "@Reducers/mobileSlice";
import {setFormVisibility, togglePassengerPicker} from "@Form/reducers/formSlice"
import useIsMobile from "@Hooks/selector/useIsMobile";
import useIsSmallTablet from "@Hooks/selector/useIsSmallTablet";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

/**
 * Toggles passenger picker type, depending on isMobile / isSmallTablet
 */
export default function useTogglePassengerPicker(): () => void {
    const dispatch = useAppDispatch()
    const isMobile = useIsMobile()
    const isSmallTablet = useIsSmallTablet()
    return () => {
        if (isSmallTablet || isMobile) {
            dispatch(toggleMobilePassengerPicker())
            dispatch(setFormVisibility(false))
        } else {
            dispatch(togglePassengerPicker())
        }
    }
}